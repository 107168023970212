import React from "react"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import { motion } from 'framer-motion'
import TestimonialSection from "../components/testimonials-section"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import Hero from "../components/hero"    
import Cta from "../components/cta"
import Seo from "../components/seo"
import ChevronDown from "../components/svg/chevronDown"
import ArrowRight from "../components/svg/arrowRight"

const WebflowPage = () => (
    <Layout>
        <Seo 
            title="Webflow Developer Sydney, Melbourne & Canberra, Australia" 
            description="Launch Lab is a senior web development agency, based in Sydney Australia, offering Webflow design and Webflow development services."
            pathname="/webflow-developer/"
        />

        <Hero
            h1="Webflow Developer Sydney & Canberra, Australia"  
            h2="We're highly experienced Webflow developers and web designers"
        />
        
        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">Trusted</span>
                            <p>We've built Webflow websites for startups, funded scaleups and well know brands.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Experienced</span>
                            <p>We've worked on more than 100 websites, UIs and startup products.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Local</span>
                            <p>Our Webflow work is done onshore in Sydney, Melbourne and Canberra.</p>
                        </div>
                    </div>
                    <div className="work-jumbo-wrapper mt-4 with-radius">
                        <motion.div 
                            className="work-jumbo-wrapper with-radius"
                            initial="hidden"
                            animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [120, 0], scale: [0.9, 1] }} 
                            transition={{ ease: "easeOut", delay: 0.5, duration: 1 }}
                        >
                            <StaticImage 
                                src="../images/launch-lab-work-2024.png"
                                alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                                className="with-radius"
                            />
                        </motion.div>
                    </div>
                    <div className="flex flex-jc">
                        <Link to="/contact/" className="btn btn-lg btn-pink">
                        <span className="flex flex-ac">
                                Start your project
                            <ArrowRight />
                        </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
          <div className="container">
            <div className="row">
              <motion.div
                initial={{ opacity: 0, y: 80 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 0.5 }}
              >
                <h3 className="small-h">Webflow Services</h3>
                <h4>A full stack design and development service to help you leverage all of Webflow's power</h4>
                <p className="lead">
                    Launch Lab offers custom web and software development services as well as no-code solutions like <strong>Webflow design and development</strong>. While our skills give us many options for developing websites, we believe that Webflow is the perfect fit for most marketing website and content management system (CMS) projects.
                </p>
                <div className="btn-row">
                    <Link to="/contact/" className="btn btn-lg btn-purple">
                        <span className="flex flex-ac">
                            Contact us
                            <ArrowRight />
                        </span>
                    </Link>
                </div>
              </motion.div>
              <motion.div 
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
              >
                <div className="services-wrapper top-row">
                  <div className="a-service">
                    <h4>
                      Web Design
                    </h4>
                    <p>We design minimal clean websites with a focus on user experience and delivering on business objectives.</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      Web Development
                    </h4>
                    <p>We follow Webflow best practice to create maintainable and fast loading websites.</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      Custom CSS &amp; Javascript
                    </h4>
                    <p>If and when required, we have skills in-house to add our own code to extend Webflow.</p>
                  </div>
                </div>
                <div className="services-wrapper">
                  <div className="a-service">
                    <h4>
                      Webflow CMS
                    </h4>
                    <p>The Webflow CMS is beautiful in its simplicity. We are sure you'll love using it. We'll ensure it is easy for you to use and we'll provide training videos on how to use it to manage your content.</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      Webflow API
                    </h4>
                    <p>If you want to use the CMS for your website and another product (like your mobile app) we have experience using the Webflow API giving you the option of centralising all your content.</p>
                  </div>
                  <div className="a-service">
                    <h4>
                      Wordpress to Webflow
                    </h4>
                    <p>If you're looking to re-design your website and move it from Wordpress to Webflow, we can help.</p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="row">
                <div className="well bg-purple">
                    <h4>Webflow for startups partner</h4>
                    <p>Launch Lab is one of the few Australian partners in the <a href="https://webflow.com/startups" target="_blank" rel="noopener noreferrer">Webflow for Startups</a> program. If you're an Australian startup and ready to build your first website please speak to us about our Webflow services and the great discounts the Webflow startup program offers.</p>
                </div>
            </div>
          </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">   
                    <h4 className="text-center">Recent Webflow Projects</h4>
                    <motion.div 
                        className="porti-row mt-3"
                        initial={{ opacity: 0, y: 120 }}
                        whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0, scale: [0.9, 1] }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: "0.25", duration: 0.5 }}
                    >
                        <Link to="/wordpress-to-webflow-case-study/" className="porti-item">
                            <div className="porti-item-img bg-noise apos">
                                <StaticImage 
                                    src="../images/apositive.png"
                                    alt="A collage of the APositive website screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>APositive</h3>
                                <p>We designed and developed a new website for this Financial services company and helped them migrate from Wordpress to Webflow.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/webflow-website-case-study/" className="porti-item">
                            <div className="porti-item-img bg-noise immu">
                                <StaticImage 
                                    src="../images/immutable-site.png"
                                    alt="Multiple screens from the Immutable website"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Immutable</h4>
                                <p>We developed the Webflow website for this Sydney-based Web3 scaleup and continue to offer ongoing Webflow support.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>

                        <Link to="/fixitdoc-case-study/" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/fixitdoc-screens.png"
                                    alt="FixitDoc mobile aplication"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>FixitDoc</h4>
                                <p>We developed a Webflow website and a mobile app which makes use of the Webflow API.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill cms">CMS</div>
                                    <div className="work-pill mobileapp">Mobile App</div>
                                    <div className="work-pill startup">Startup</div>
                                </div>
                            </div>
                        </Link>

                        <a href="https://www.digitalworldsnfts.com/" target="_blank" rel="noopener noreferrer" title="View the Digital Worlds NFTs website" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/gods-unchained.png"
                                    alt="Gods Unchained web3 game"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Digital Worlds NFTs</h4>
                                <p>Launch Lab developed a Webflow website for this non-profit organisation.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </a>
                    </motion.div>
                </div>
                
                <div className="flex flex-jc">
                    <Link to="/webflow-portfolio/" className="btn btn-lg btn-purple">
                    <span className="flex flex-ac">
                        More Webflow projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Are you Australian Webflow developers? <ChevronDown />
                                </>
                                }
                            >
                                <h5>Webflow Sydney</h5>
                                <p>Yes. We do all of our Webflow work in Sydney, Melbourne and Canberra. However, we're happy to work with startups, scaleups and larger companies from other countries like USA, Canada, New Zealand and England.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you also design the websites that you develop? <ChevronDown />
                                </>
                                }
                            >
                                <p>Mostly. We offer a <Link to="/web-design/">web design service</Link> too. However, if you already have designs in Figma, Sketch, or Adobe XD we can help turn your designs into a website.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you have Webflow Enterprise experience? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. We have <a href="https://webflow.com/enterprise" target="_blank" rel="noopener noreferrer">Webflow Enterprise</a> experience including using the additional features that come with Enterprise accounts. We were also lucky enough to get access to the Localization Beta before this new translation and localization feature launched.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you only offer Webflow development? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. We also develop websites in Gatsby.js and use headless CMS' like Contentful. Plus, we develop serverless applications using Python, React and AWS.</p>
                                <p>View all of our <Link to="/services/"> web development services</Link>.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Why should I use Webflow for my website? <ChevronDown />
                                </>
                                }
                            >
                                <p>Using a low-code platform like Webflow might seem like a strange choice for a company like Launch Lab considering we have all the skills required to write our own code and host websites ourselves.</p>
                                <p>However, we fell in love with Webflow soon after first using it.</p>
                                <p>It speeds projects up and means that our Javascript and Python developers can focus on developing custom applications and software, while still being available when we're required to write custom code to extend Webflow.</p>
                                <p>With that said, here are the reasons why you'll love Webflow:</p>
                                <ul>
                                    <li>Faster web development projects</li>
                                    <li>Lower development costs</li>
                                    <li>Hassle free hosting on a globally scalable CDN</li>
                                    <li>An easy to use CMS without the hassle of upgrading plugins</li>
                                    <li>A multi billion dollar company working behind the scenes to continually improve their product</li>
                                </ul>
                                <p>If you're still unsure if Webflow is right for you, read this blog post that provides the reasons <Link to="/webflow-for-your-next-website/">why you should consider Webflow for your next website</Link>.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Is it a low-code or a no-code platform? <ChevronDown />
                                </>
                                }
                            >
                                <p>Webflow is marketed as a no-code solution to developing websites. However, we prefer to say 'low-code' because adding custom Javascript, and sometimes CSS, to projects takes them further.</p>
                                <p>If it was completely no-code then calling ourselves <strong>Webflow developers</strong> would be strange. Maybe that should be Webflow designers or Webflow builders!</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What is the usual project size you work with? <ChevronDown />
                                </>
                                }
                            >
                                <p>We're happy to work with small budgets through to larger budgets (we're currently using Webflow with a few early stage startups as well as a startup that has raised hundreds of millions of dollars).</p>
                                <p>If you have a very small budget and want help choosing a Webflow theme and customising it, or if you have a larger budget and need a custom built Webflow website we'd love to discuss your project with you.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Is Webflow a good choice for SEO? <ChevronDown />
                                </>
                                }
                            >
                                <p>Webflow SEO provides all the tools we need for your on-page SEO. From easy implementation of 301 redirects, to declaring canonical urls, and the usual SEO meta titles and descriptions, Webflow provides more options than most other website builders</p>
                                <p>Plus the Webflow Designer interface allows for declaration of html selectors and custom code when required.</p>
                                <p>Finally, Webflow sites load quickly. They use the fastest CDN in the world and are backed by a global infrastructure.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer Webflow support? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. Most of the Webflow websites we build are done from scratch and we offer ongoing support to these clients. However, if you already have a Webflow site and need ongoing design and development support we're happy to help.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer Webflow training? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. Once we've completed your website we'll provide Loom videos showing how to use the CMS and how you can support your own website. We make these videos to spec so the content is specific to your website.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="Get a Webflow quote from a highly experienced, onshore, team of Australian web designers and developers"
        />
    </Layout>
)

export default WebflowPage